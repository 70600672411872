<template>
  <v-container>
    <v-row class="pa-10" v-if="loading">
      <v-col cols="12">
        <loader></loader>
      </v-col>
    </v-row>
    <v-simple-table class="py-3" fixed-header v-if="notifications.length !== 0">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">
            Module
          </th>
          <th class="text-center">
            Title
          </th>
          <th class="text-center">
            Message
          </th>
          <th class="text-center">
            Created At
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="item in notifications"
            :key="item.name"
            style="cursor: pointer"
            @click.stop="goToNotificationLink(item)"
        >
          <td>
            <v-avatar :color="goToNotificationColor(item)" size="40">
              <v-icon dark>
                {{ getNotificationIcon(item) }}
              </v-icon>
            </v-avatar>
          </td>
          <td>{{ limitSize(item.title, 150) }}</td>
          <td>{{ limitSize(item.message, 150) }}</td>
          <td>{{ formatDate(item.created_at, "DD.MM.YYYY HH:mm") }}
          <div class="text-decoration-underline primary--text"
               v-if="markAsReadButton && !item.pivot.seen_at" :style="{ cursor: 'pointer' }"
               @click="markAsRead(item.id)">Mark as read</div>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row v-if="notifications.length === 0">
      <v-card flat class="text-center title px-8 py-6">
        All caught up - no new notifications
      </v-card>
    </v-row>
    <v-row v-if="loadMoreButton">
      <v-btn
          color="primary"
          block
          :disabled="notifications.length === total"
          @click.native.stop="loadMoreNotifications"
      >
        Load More
      </v-btn>
    </v-row>
  </v-container>
</template>
<script>
import date from "@/mixins/date";
import string from "@/mixins/string";

export default {
  name: "NotificationLIst",
  mixins: [date, string],
  components: {
    Loader: () => import("@/components/core/Loader")
  },
  data() {
    return {
      item: {},
      title: "Notifications",
    };
  },
  props: {
    unseen: {
      type: Boolean,
      default: false
    },
    actionName: {
      type: String,
      default: "getItems"
    },
    getterName: {
      type: String,
      default: "items"
    },
    loadingName: {
      type: String,
      default: "loading"
    },
    markAsReadButton: {
      type: Boolean,
      default: false
    },
    loadMoreButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    loading: {
      get() {
        return this.$store.getters["notifications/" + this.loadingName];
      }
    },
    notifications: {
      get() {
        return this.$store.getters["notifications/" + this.getterName];
      }
    },
    total: {
      get() {
        return this.$store.getters["notifications/total"];
      }
    },
    pagination: {
      get() {
        return this.$store.getters["notifications/pagination"];
      }
    },
    user() {
      return JSON.parse(this.$auth.remember()) || {};
    }
  },
  async mounted() {
    await this.getNotifications();
  },
  watch: {
    pagination: {
      handler() {
        this.getNotifications();
      },
      deep: true
    }
  },
  methods: {
    async getNotifications() {
      await this.$store.dispatch("notifications/" + this.actionName, {
        id: this.user.id,
        unseen: this.unseen,
        pagination: this.pagination
      });
    },
    getNotificationClass(notification) {
      switch (notification.notifiable_type) {
        case "App\\Models\\est":
          return "workforce";
        case "App\\Models\\User":
          return "user";
        case "App\\Models\\Employee":
          return "employee";
        default:
          return "employee";
      }
    },
    goToNotificationLink(notification) {
      this.$router.push({
        name: "show-" + this.getNotificationClass(notification),
        params: {id: notification.notifiable_id}
      });
    },
    goToNotificationColor(notification) {
      let notificationClass = this.getNotificationClass(notification);
      switch (notificationClass) {
        case "employee":
          return "light-blue darken-1";
        default:
          return "primary";
      }
    },
    getNotificationIcon(notification) {
      let notificationClass = this.getNotificationClass(notification);
      switch (notificationClass) {
        case "user":
          return "mdi-account-circle";
        case "employee":
          return "mdi-account-hard-hat";
        default:
          return "mdi-lan";
      }
    },
    async markAsRead(id) {
      await this.$store
          .dispatch("notifications/markAsRead", {
            id: id,
            user_id: this.user.id
          })
          .then(() => this.getNotifications());
    },
    async loadMoreNotifications() {
      await this.$store.dispatch("notifications/setPagination", {
        perPage: this.pagination.perPage + this.pagination.loadRows
      });
    }
  }
};
</script>
